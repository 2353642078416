<template>
  <div>
    <h3 ref="ia">
      Triangle Sum Theorem
    </h3>
    <p>
      Triangle sum theorem states that the three interior angles of a triangle always add to 180<sup>o</sup>.
      In other words, if you cut out the three
      angles of a triangle and place them adjacent to each other, they form a straight angle.
      Consider a triangle formed by three points O, A and B &mdash; as shown in the figure below.
      The three angles of the triangle are &alpha;, &beta;, and &gamma;. Then, according to
      triangle sum theorem &mdash;
      $$\alpha + \beta + \gamma = 180^o$$
      This theorem is not a special case but is a fundamental property of triangles, i.e., this is true for all triangles.
    </p>
    <br>
    <v-layout justify-center>
      <v-img src="/assets/triangle_sum.png"
             max-height="450px"
             max-width="450px"
             contain
      />
    </v-layout>
    <h3 ref="playgraph1">
      MagicGraph 1. &vert; Three angles of a triangle form a half circle
    </h3>
    <p>
      This MagicGraph offers a geometric approach to understand why the sum of three angles of a triangle is always 180<sup>o</sup>.
    </p>
    <h5> To Get Started: </h5>
    <p>
      You are given a triangle formed by points O, A and B.
      The vertices O, A, and B of the triangle can be dragged and moved around to change
      the type (i.e., acute, obtuse or right) and size of the triangle.
      You will be able to see that the three interior angles &alpha;, &beta; and &gamma; change as the vertices are dragged around.
    </p>
    <h5> To Explore:</h5>
    <p>
      Drag points O, A and B of the triangle to change the type (i.e., acute, obtuse or right) and size of the triangle OAB.
      Then, tap on the two scissors to cut out the angles &alpha; and &beta;.
      No matter what type or size of the triangle you choose, the three interior
      angles &alpha;, &beta; and &gamma; always form a half circle when stacked adjacent to each other.
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox2" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
    <h3 ref="playgraph2">
      MagicGraph 2. &vert; Sum of the three angles of a triangle is always 180<sup>o</sup>
    </h3>
    <p>
      This MagicGraph offers a direct approach to verify that the sum of three angles of a triangle is always 180<sup>o</sup>.
    </p>
    <h5> To Get Started: </h5>
    <p>
      You are given a triangle formed by points O, A and B. The vertices O, A, and B of the triangle can be dragged and moved around to
      change the type (i.e., acute, obtuse or right) and size of the triangle.
      You will be able to see that the three interior angles &alpha;, &beta; and &gamma; change as the vertices O, A and B are dragged around.
    </p>
    <h5> To Explore:</h5>
    <p>
      Drag points O, A and B of the triangle to change the type (i.e., acute, obtuse or right) and size of the triangle OAB.
      Then, tap on the two scissors to cut out the angles &alpha; and &beta;.
      No matter what type or size of the triangle you choose, the three interior
      angles &alpha;, &beta; and &gamma; always add to 180<sup>o</sup>.
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'AnglesofaTriangle',
  created: function () {
      this.$store.commit('navigation/resetState');
    // Store mutations
    let title = 'Triangle Sum Theorem';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Triangle Sum Theorem', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'MagicGraph # 1',img:'/assets/touch.svg', action: () => this.goto('playgraph1')},
      {title: 'MagicGraph # 2',img:'/assets/touch.svg', action: () => this.goto('playgraph2')},

    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
    Boxes.box2();
  },
  metaInfo() {
  return{ title: 'Triangle Sum Theorem',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about triangle sum theorem.'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
</style>
