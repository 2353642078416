const Boxes = {
  box1: function () {
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-6, 10, 6, -2],
        keepaspectratio: true, axis:false, ticks:{visible:false},
        grid:true, showCopyright:false, showNavigation:false,
        pan:{enabled:false}, zoom:{enabled:false}});
        brd1.suspendUpdate();
        //title
        var resize1 = function () {
        brd1.resizeContainer(brd1.containerObj.clientWidth, brd1.containerObj.clientHeight, true);
        brd1.fullUpdate();
        };
        window.addEventListener("resize", resize1);
      //
    brd1.create('text', [0, 9., 'Sum of the Interior Angles of a Triangle'],{display:'internal', anchorX:'middle', anchorY:'bottom',CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(32*brd1.canvasWidth/800.)}, fixed:true, highlight:false});
    var i=0; var j=0;
    var Pt1 = brd1.create('point', [0,0],{name:'O (Drag me!)', size:3, fillColor:'white', strokeColor:'black', snapToGrid:false, label: {offset:[0, -15], fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    var Pt2 = brd1.create('point', [2,3],{name:'A (Drag me!)', size:3, fillColor:'white',strokeColor:'black', snapToGrid:false,label: {offset:[0, 15],fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    var Pt3 = brd1.create('point', [-2,2],{name:'B (Drag me!)', size:3,fillColor:'white',  strokeColor:'black', snapToGrid:false,label: {offset:[0, 15],fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    brd1.create('segment', [Pt1, Pt2], {strokeColor:'blue', strokeWidth:1, fixed:true});
    brd1.create('segment', [Pt1, Pt3], {strokeColor:'blue', strokeWidth:1, fixed:true});
    brd1.create('polygon', [Pt1, Pt2, Pt3],{fixed:true, strokeWidth:2, fillColor:'white', fillOpacity:1, highlight:false, borders:{fixed:true, strokeColor:'blue'},visible:function(){if(i+j<2){return true}else{return false}}});
	brd1.create('angle', [Pt3, Pt2, Pt1],{radius:1, strokeColor:'blue', fillColor:'blue', visible:function(){if(i==0){return true}else{return false}}, label:{color:'blue', fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    var ang1 = brd1.create('angle', [Pt3, Pt2, Pt1],{radius:1, strokeColor:'blue', fillColor:'blue', visible:function(){if(i==0){return false}else{return false}}, label:{color:'blue', fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    var ang2 = brd1.create('angle', [Pt2, Pt1, Pt3],{radius:1, strokeColor:'red', fillColor:'red', label: {color:'red', fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    var ang3 = brd1.create('angle', [Pt1, Pt3, Pt2],{radius:1, strokeColor:'green', fillColor:'green',visible:function(){if(j==0){return true}else{return false}}, label: {color:'green',fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    var ang = brd1.create('angle', [[50, 0], Pt1, Pt2],{radius:1, strokeColor:'green', fillColor:'green',visible:false});
    //
    //var sci1 =brd1.create('image', ['/assets/scissors.svg',[function(){return Pt2.X()-Math.cos(ang.Value())-0.25;}, function(){return Pt2.Y()-Math.sin(ang.Value())-0.25;}], [0.5,0.5]],{visible:function(){if(i<1){return true}else{return false}}});
    //var sci2= brd1.create('image', ['/assets/scissors.svg',[function(){return Pt3.X()-Math.cos(ang.Value()+ang2.Value())-0.25;}, function(){return Pt3.Y()-Math.sin(ang.Value()+ang2.Value())-0.25;}], [0.5,0.5]],{visible:function(){if(j<1){return true}else{return false}}});
    //var sci3= brd1.create('image', ['/assets/scissors.svg',[function(){return Pt1.X()+Math.cos(ang.Value())-0.25;}, function(){return Pt1.Y()+ Math.sin(ang.Value())-0.25}], [0.5,0.5]],{visible:function(){if(j<1){return true}else{return false}}});
    var trot = brd1.create('transform', [function(){return -ang1.Value();}, Pt1], {type:'rotate'});
    var Pt4 = brd1.create('point', [Pt2, trot],{name:'', size:0,visible:function(){if(i==1){return true}else{return false}}});
    brd1.create('angle', [Pt4, Pt1, Pt2],{name:'&alpha;', fillColor:'blue', strokeColor:'blue', radius:1,visible:function(){if(i==1){return true}else{return false}},label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    var trot2 = brd1.create('transform', [function(){return ang3.Value();}, Pt1], {type:'rotate'});
    var Pt5 = brd1.create('point', [Pt3, trot2],{name:'', size:0, visible:function(){if(j==1){return true}else{return false}}});
    //sci1.on('down', function(){return i= i+1;});
    //sci2.on('down', function(){return j=j+1;});
    Pt3.on('down', function(){i=0; j=0; return;});
    Pt2.on('down', function(){i=0; j=0;  return;});
    Pt1.on('down', function(){i=0; j=0;  return;});
    //brd1.create('angle', [Pt3, Pt1, Pt5],{name:'&gamma;', fillColor:'green', strokeColor:'green', radius:1,visible:function(){if(j==1){return true}else{return false}},label: {color:'green', fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    //brd1.create('segment', [Pt1, Pt4],{strokeWidth:1, strokeColor:'blue',visible:function(){if(i==1){return true}else{return false}}});
    ///brd1.create('segment', [Pt1, Pt5],{strokeWidth:1, strokeColor:'blue',visible:function(){if(j==1){return true}else{return false}}});
    brd1.create('text', [-2, 8.5, function(){return '&alpha;= '+ (ang1.Value()*180/Math.PI).toFixed(2) + '^o'}],{anchorX:'middle', highlight:false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}, fixed:true, highlight:false});
    brd1.create('text', [0, 8.5, function(){return '&beta;= '+ (ang2.Value()*180/Math.PI).toFixed(2)+ '^o'}],{anchorX:'middle', highlight:false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}, fixed:true});
    brd1.create('text', [2, 8.5, function(){return '&gamma;= '+ (ang3.Value()*180/Math.PI).toFixed(2) + '^o'}],{anchorX:'middle', highlight:false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)},fixed:true});
    brd1.create('text', [0, 7.5, function(){return '&alpha; + &beta; + &gamma; = '+ (ang1.Value()*180/Math.PI).toFixed(2) +'^o + ' + (ang2.Value()*180/Math.PI).toFixed(2)+'^o + '+(ang3.Value()*180/Math.PI).toFixed(2) + '^o' + ' = 180^o' }],{anchorX:'middle', highlight:false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)},fixed:true});
    brd1.unsuspendUpdate();
    },
box2: function () {
  var brd2 = JXG.JSXGraph.initBoard('jxgbox2',{boundingbox: [-6, 10, 6, -2],
      keepaspectratio: true, axis:false, ticks:{visible:false},
      grid:true, showCopyright:false, showNavigation:false,
      pan:{enabled:false}, zoom:{enabled:false}});
      //title
      brd2.suspendUpdate();
      var resize2 = function () {
      brd2.resizeContainer(brd2.containerObj.clientWidth, brd2.containerObj.clientHeight, true);
      brd2.fullUpdate();
      };
      window.addEventListener("resize", resize2);
      brd2.options.layer['image'] = 16;
    //
  //brd2.create('text', [-6, 7., '<b> Sum of the Interior angles of a Triangle <b>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd2.canvasWidth/500.)}, fixed:true});
  brd2.create('text', [0, 9., 'Sum of the Interior Angles of a Triangle'],{display:'internal', anchorX:'middle', anchorY:'bottom',CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(32*brd2.canvasWidth/800.)}, fixed:true, highlight:false});

  var i=0; var j=0;
  var Pts1 = brd2.create('point', [0,0],{name:'O (Drag me!)', highlight:false, size:3, fillColor:'white', strokeColor:'black', snaPtsoGrid:true, label: {offset:[0, -20], fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var Pts2 = brd2.create('point', [2,3],{name:'A (Drag me!)', size:3, fillColor:'white',strokeColor:'black', snaPtsoGrid:true,label: {offset:[0, 20], fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var Pts3 = brd2.create('point', [-2,2],{name:'B (Drag me!)', size:3, fillColor:'white', strokeColor:'black', snaPtsoGrid:true,label: {offset:[0, 20], fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  brd2.create('segment', [Pts1, Pts2], {strokeColor:'black', strokeWidth:1, fixed:true, dash:()=>i.valueOf(), highlight:false});
  brd2.create('segment', [Pts1, Pts3], {strokeColor:'black', strokeWidth:1, fixed:true, dash:()=>j.valueOf(), highlight:false});
  brd2.create('segment', [Pts2, Pts3], {strokeColor:'black', strokeWidth:1, fixed:true, dash:()=>j.valueOf(), highlight:false});
  brd2.create('polygon', [Pts1, Pts2, Pts3],{fixed:true, strokeWidth:2, fillColor:'white',highlight:false, fillOpacity:1, borders:{visible:false, fixed:true, strokeColor:'black'},visible:function(){if(i+j<2){return true}else{return false}}});
  var angs1 = brd2.create('angle', [Pts3, Pts2, Pts1],{radius:1, strokeColor:'blue', fillColor:'blue', visible:function(){if(i==0){return true}else{return false}}, label:{color:'blue', fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
 //brd2.create('angle', [Pts1, Pts2, Pts3],{radius:1, strokeColor:'blue', fillColor:'blue', visible:function(){if(i==0){return true}else{return false}}, label:{color:'blue', fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  //brd2.create('minorsector', [Pts2, Pts1, Pts3],{radius:1, strokeColor:'blue', fillColor:'blue', visible:function(){if(i==0){return true}else{return false}}, label:{color:'blue', fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var angs2 = brd2.create('angle', [Pts2, Pts1, Pts3],{radius:1, strokeColor:'red', fillColor:'red', label: {color:'red',fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var angs3 = brd2.create('angle', [Pts1, Pts3, Pts2],{radius:1, strokeColor:'green', fillColor:'green',visible:function(){if(j==0){return true}else{return false}}, label: {color:'green', fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var angs = brd2.create('angle', [[50, 0], Pts1, Pts2],{radius:1, strokeColor:'green', fillColor:'green',visible:false});
  //
  //var m1 = brd2.create('midpoint', [Pts1, Pts2]);
  //var m2 = brd2.create('midpoint', [Pts1, Pts3]);
  var sci1 =brd2.create('image', ['/assets/scissors.svg',[function(){return Pts2.X()-Math.cos(angs.Value())-0.25;}, function(){return Pts2.Y()-Math.sin(angs.Value())-0.25;}], [0.5,0.5]],{visible:function(){if(i<1){return true}else{return false}}});
  var sci2= brd2.create('image', ['/assets/scissors.svg',[function(){return Pts3.X()-Math.cos(angs.Value()+angs2.Value())-0.25;}, function(){return Pts3.Y()-Math.sin(angs.Value()+angs2.Value())-0.25;}], [0.5,0.5]],{visible:function(){if(j<1){return true}else{return false}}});
  //var sci3= brd2.create('image', ['/assets/scissors.svg',[function(){return Pts1.X()+Math.cos(angs.Value())-0.25;}, function(){return Pts1.Y()+ Math.sin(angs.Value())-0.25}], [0.5,0.5]],{visible:function(){if(j<1){return true}else{return false}}});
  var trot = brd2.create('transform', [function(){return -angs1.Value();}, Pts1], {type:'rotate'});
  var Pts4 = brd2.create('point', [Pts2, trot],{name:'', size:0,visible:function(){if(i==1){return true}else{return false}}});
  brd2.create('angle', [Pts4, Pts1, Pts2],{name:'&alpha;', fillColor:'blue', strokeColor:'blue', radius:1,visible:function(){if(i==1){return true}else{return false}},label: {fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var trot2 = brd2.create('transform', [function(){return angs3.Value();}, Pts1], {type:'rotate'});
  var Pts5 = brd2.create('point', [Pts3, trot2],{name:'', size:0, visible:function(){if(j==1){return true}else{return false}}});
  sci1.on('down', function(){return i= i+1;});
  sci2.on('down', function(){return j=j+1;});
  Pts3.on('down', function(){i=0; j=0; return;});
  Pts2.on('down', function(){i=0; j=0;  return;});
  Pts1.on('down', function(){i=0; j=0;  return;});
  brd2.create('angle', [Pts3, Pts1, Pts5],{name:'&gamma;', fillColor:'green', strokeColor:'green', radius:1,visible:function(){if(j==1){return true}else{return false}},label: {color:'green', fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  brd2.create('segment', [Pts1, Pts4],{strokeWidth:1, strokeColor:'black',visible:function(){if(i==1){return true}else{return false}}});
  brd2.create('segment', [Pts1, Pts5],{strokeWidth:1, strokeColor:'black',visible:function(){if(j==1){return true}else{return false}}});
  //brd2.create('text', [-6, 5.5, function(){return '&alpha;= '+ (angs1.Value()*180/Math.PI).toFixed(2) + '^o'}],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd2.canvasWidth/500.)}, fixed:true});
  //brd2.create('text', [-4, 5.5, function(){return '&beta;= '+ (angs2.Value()*180/Math.PI).toFixed(2)+ '^o'}],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd2.canvasWidth/500.)}, fixed:true});
  //brd2.create('text', [-2, 5.5, function(){return '&gamma;= '+ (angs3.Value()*180/Math.PI).toFixed(2) + '^o'}],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd2.canvasWidth/500.)}, fixed:true});
//
  brd2.create('text', [-2, 8.5, function(){return '&alpha;= '+ (angs1.Value()*180/Math.PI).toFixed(2) + '^o'}],{anchorX:'middle', highlight:false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd2.canvasWidth/500.)}, fixed:true, highlight:false});
  brd2.create('text', [0, 8.5, function(){return '&beta;= '+ (angs2.Value()*180/Math.PI).toFixed(2)+ '^o'}],{anchorX:'middle', highlight:false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd2.canvasWidth/500.)}, fixed:true});
  brd2.create('text', [2, 8.5, function(){return '&gamma;= '+ (angs3.Value()*180/Math.PI).toFixed(2) + '^o'}],{anchorX:'middle', highlight:false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd2.canvasWidth/500.)},fixed:true});
  //
  brd2.create('text', [-2, 7.5, function(){return 'Sum = '+ (angs1.Value()*180/Math.PI).toFixed(2) +'^o + ' + (angs2.Value()*180/Math.PI).toFixed(2)+'^o + '+(angs3.Value()*180/Math.PI).toFixed(2) + '^o' + ' = 180^o' }],{anchorX:'middle', highlight:false, visible:function(){if(i+j<2){return false}else{return true}}, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd2.canvasWidth/500.)}, fixed:true});
  brd2.unsuspendUpdate();
},
}
export default Boxes;
